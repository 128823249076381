import React, { useState, useEffect } from "react";
import { Container, CircularProgress, Typography } from "@mui/material";
import { toast } from "react-toastify";
import DetectionExplorer from "../components/DetectionExplorer.jsx";
import theme from '../theme.jsx';

const DetectionDashBoard = (props) => {
  const { globalUrl, userdata, isLoading, } = props;
  const [ruleInfo, setRuleInfo] = useState("default");
  const [, setSelectedRule] = useState(null);
  const [, setFileData] = useState("");
  const [isDetectionActive, setIsDetectionActive] = useState(false);
  const [folderDisabled, setFolderDisabled] = useState(false);
  const [rulesLoading, setRulesLoading] = useState(false);
  const [importAttempts, setImportAttempts] = useState(0);
  const [detectionInfo, setDetectionInfo] = useState({
	  title: "",
	  category: "",
  })

  const [ruleMapping, setRuleMapping] = useState({})
  const [rulemappingLoaded, setRulemappingLoaded] = useState(false)

  const maxImportAttempts = 1

  useEffect(() => {
    fetchDetectionInfo()
  }, [])

  useEffect(() => {
	if (ruleInfo !== undefined && ruleInfo !== null && ruleInfo.length > 0) {
	} else {
		console.log("Got ruleInfo: ", ruleInfo)
		if (ruleInfo !== "default") {
    		//importDetectionFromUrl(false)
		}
	}
  }, [ruleInfo])

  const openEditBar = (rule) => {
    setSelectedRule(rule);
    fetchFileContent(rule.file_id);
  };

  const handleSave = (updatedContent) => {
    toast("This will be saved");
  }

  const fetchDatastoreRulemapping = () => {
	console.log("Loading rulemapping")

	if (userdata === undefined || userdata === null || userdata.active_org === undefined || userdata.active_org === null) {
		console.log("No active org found in userdata: ", userdata)
		return
	}

	setRulemappingLoaded(true)

	const path = window.location.pathname;
	const parts = path.split("/");
	const detectionKey = parts[parts.length - 1];
	
	toast("Getting rulemapping from cache")
	const orgId = userdata.active_org.id

    const url = `${globalUrl}/api/v1/orgs/${orgId}/get_cache`
	const searchQuery = {
		"org_id": orgId,
		"key": "rule_mapping_" + detectionKey,
	}

    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
	  body: JSON.stringify(searchQuery),
    })
	.then((response) => response.json())
	.then((responseJson) => {
		console.log("Got rule mapping: ", responseJson)

		if (responseJson === undefined || responseJson === null || responseJson.success === false) {
			setRuleMapping({
				"success": true,
				"value": [],
			})

		} else {
			setRuleMapping(responseJson)
		}
	})
	.catch((error) => {
		console.log("Error in getting rule mapping: ", error);
	})
  }

  if (rulemappingLoaded === false) {
	  fetchDatastoreRulemapping()
  }

  const fetchFileContent = (file_id) => {
    setFileData("");
    fetch(`${globalUrl}/api/v1/files/${file_id}/content`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log("Status not 200 for file :O!");
          return "";
        }
        return response.text();
      })
      .then((respdata) => {
        if (respdata.length === 0) {
          toast("Failed getting file. Is it deleted?");
          return;
        }
        setFileData(respdata);
      })
      .catch((error) => {
        toast(error.toString());
      });
  };

  const fetchDetectionInfo = () => {
	// Get the current key from react-router path="/detections/:key"
	const path = window.location.pathname;
	const parts = path.split("/");
	const detectionKey = parts[parts.length - 1];

    const url = `${globalUrl}/api/v1/detections/${detectionKey}`;
    setRulesLoading(true);

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson["success"] === false) {
          toast("Failed to get rules");
        } else {
  		  setDetectionInfo(responseJson)

          setRuleInfo(responseJson.detection_info || [])
          setFolderDisabled(responseJson.folder_disabled)

		  if (responseJson.detection_active !== undefined && responseJson.detection_active !== null) {
          	setIsDetectionActive(responseJson.detection_active)
		  }
        }

        setRulesLoading(false);
      })
      .catch((error) => {
        setRulesLoading(false);
        console.log("Error in getting detection files: ", error);
        toast("An error occurred while fetching detection rules");
        setRuleInfo([]);
      });
  };

  const importDetectionFromUrl = (force_download, url) => {
	if (url === undefined || url === null || url === "") {
		toast("No URL provided for importing rules")
		return
	}

	if (force_download !== true && importAttempts >= maxImportAttempts) {
		return
	}

	if (force_download !== true && ruleInfo?.length > 0) {
		return
	}

    setImportAttempts((prevAttempts) => prevAttempts + 1)
    setRulesLoading(true)
	const parts = window.location.pathname.split("/")
	const folder = (parts[parts.length - 1]).toLowerCase()

    const parsedData = {
      url: url,
      path: folder,
      field_3: "main",
    };

    toast.info(`Downloading sample rules from url ${url}. Please wait...`);
    fetch(`${globalUrl}/api/v1/files/download_remote`, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify(parsedData),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success) {
          //toast("Successfully loaded files from " + url);
          fetchDetectionInfo() // Fetch again after successful import
        } else {
          toast(responseJson.reason ? `Failed loading rules: ${responseJson.reason}` : "Failed loading rules");
        }
        setRulesLoading(false);
      })
      .catch((error) => {
        toast(error.toString());
        setRulesLoading(false);
      });
  };

  /*
  if (rulesLoading && (!ruleInfo || ruleInfo.length === 0)) {
    return (
      <Container style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <div>
          <CircularProgress />
          <Typography variant="h6" style={{ marginTop: 20 }}>Downloading rules, please wait...</Typography>
        </div>
      </Container>
    );
  }
  */

  return (
    <Container style={{ display: "flex" }}>
      <DetectionExplorer
        globalUrl={globalUrl}
	    userdata={userdata}
        ruleInfo={ruleInfo}
        folderDisabled={folderDisabled}
        setFolderDisabled={setFolderDisabled}
	    isDetectionActive={isDetectionActive}
	    setIsDetectionActive={setIsDetectionActive}

	    rulesLoading={rulesLoading}
	    detectionInfo={detectionInfo}
  		importDetectionFromUrl={importDetectionFromUrl} 

	    ruleMapping={ruleMapping}
		setRuleMapping={setRuleMapping}
      />
    </Container>
  );
};

export default DetectionDashBoard;
